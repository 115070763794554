import { configureStore } from '@reduxjs/toolkit';

import menuSlice from 'features/menu/menuSlice';
import authSlice from 'features/auth/authSlice';
import videoSlice from 'features/video/videoSlice';
import loaderSlice from 'features/loader/loaderSlice';
import courseSlice from 'features/course/courseSlice';
import exploreSlice from 'features/explore/exploreSlice';
import categorySlice from 'features/category/categorySlice';
import dashboardSlice from 'features/dashboard/dashboardSlice';
import subcategorySlice from 'features/subcategory/subcategorySlice';

export const store = configureStore({
    reducer: {
        menu: menuSlice,
        auth: authSlice,
        video : videoSlice,
        loader: loaderSlice,
        course: courseSlice,
        explore: exploreSlice,
        category: categorySlice,
        dashboard: dashboardSlice,
        subcategory : subcategorySlice
    }
})