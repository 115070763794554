export default {

  // --- USER END-POINTS --- //

  LOGIN_USER: 'POST /ADMIN/LOGIN',

  // --- CATEGORY END-POINTS --- //

  GET_CATEGORY : '/CATEGORY/CMS/ALL',
  GET_CATEGORY_BY_ID : '/CATEGORY/CMS/GET?',
  ADD_CATEGORY : 'POST /CATEGORY/CMS/ADD',
  DELETE_CATEGORY : 'DELETE /CATEGORY/CMS/DELETE?',
  UPDATE_CATEGORY : 'PATCH /CATEGORY/CMS/UPDATE',

  // --- SUBCATEGORY END-POINTS --- //

  GET_SUBCATEGORY : '/SUB-CATEGORY/CMS/ALL',
  GET_SUBCATEGORY_BY_ID : '/SUB-CATEGORY/CMS/GET?',
  ADD_SUBCATEGORY : 'POST /SUB-CATEGORY/CMS/ADD',
  LINK_SUBCATEGORY : 'POST /CATEGORY/CMS/ADD/SUBCATEGORY',
  DELETE_SUBCATEGORY : 'DELETE /SUB-CATEGORY/CMS/DELETE?',
  UPDATE_SUBCATEGORY : 'PATCH /SUB-CATEGORY/CMS/UPDATE',

  // --- COURSE END-POINTS --- //
  
  GET_COURSE : '/COURSES/CMS/ALL?',
  GET_COURSE_BY_ID : '/COURSES/CMS/GET?',
  ADD_COURSE : 'POST /COURSES/CMS/ADD',
  DELETE_COURSE : 'DELETE /COURSES/CMS/DELETE?',
  UPDATE_COURSE : 'PATCH /COURSES/CMS/UPDATE',
  REARRANGE_PLAYLISTS : 'POST /COURSES/CMS/REARRANGE',

  // --- VIDEO END-POINTS  --- //

  GET_VIDEO_BY_ID : '/VIDEO/CMS/GET?',
  ADD_VIDEO : 'POST /VIDEO/CMS/ADD',
  DELETE_VIDEO : 'DELETE /VIDEO/CMS/DELETE?',
  UPDATE_VIDEO : 'PATCH /VIDEO/CMS/UPDATE',
  CHANGE_VIDEO_THUMBNAIL : 'PATCH /VIDEO/CMS/CHANGE-THUMBNAIL',
  CHANGE_VIDEO_CONTENT : 'PATCH /VIDEO/CMS/CHANGE-CONTENT',

  // --- EXPLORE --- //

  GET_EXPLORE : '/COURSES/CMS/EXPLORE?',
  ADD_COURSE_TO_EXPLORE : 'POST /COURSES/CMS/ADD-EXPLORE',
  UPDATE_OR_REMOVE_COURSE_FROM_EXPLORE : 'POST /COURSE/UPDATE-EXPLORE',

}
