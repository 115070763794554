import dashboard from './dashboard';
import pages from './pages';
import explore from './explore';

import { getRoleBasedRoutes } from 'utils';

// ==============================|| MENU ITEMS ||============================== //

// const filteredMenuItems = getRoleBasedRoutes([dashboard, pages, explore]);

export default {
    items: [dashboard, pages]
};
