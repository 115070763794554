// assets
import { IconBrandEdge } from '@tabler/icons';

// constant
const icons = { IconBrandEdge };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const explore = {
    id: 'explore',
    title: 'Explore',
    type: 'group',
    children: [
        {
            id: 'explore',
            title: 'Explore',
            type: 'item',
            url: '/dashboard/explore',
            icon: icons.IconBrandEdge,
            breadcrumbs: false
        }
    ]
};

export default explore;
