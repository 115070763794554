// assets
import { IconKey } from '@tabler/icons';
import { authRoles } from 'config/roles';

// constant
const icons = { IconKey };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'masters',
    title: 'Masters',
    // caption: 'All master pages here',
    type: 'group',
    children: [
        {
            id: 'master',
            title: 'Master',
            type: 'collapse',
            icon: icons.IconKey,
            children: [
                {
                    id: 'category',
                    title: 'Category Master',
                    type: 'item',
                    url: '/dashboard/category-master',
                    target: false,
                    role : authRoles.admin
                },
                {
                    id: 'subcategory',
                    title: 'SubCategory Master',
                    type: 'item',
                    url: '/dashboard/subcategory-master',
                    target: false,
                    role : authRoles.admin
                },
                {
                    id: 'course',
                    title: 'Course Master',
                    type: 'item',
                    url: '/dashboard/course-master',
                    target: false,
                    role : authRoles.user
                },
                {
                    id: 'video',
                    title: 'Video Master',
                    type: 'item',
                    url: '/dashboard/video-master',
                    target: false,
                    role : authRoles.user
                }
            ]
        }
    ]
};

export default pages;
