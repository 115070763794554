// material-ui
import { Typography } from '@mui/material';
import { useEffect } from 'react';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { getRoleBasedRoutes } from '../../../../utils';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {

    let navItems = menuItem.items;

    useEffect(() => {

        navItems = getRoleBasedRoutes(navItems);

    }, []);

    const finalNavItems = () => {

        return navItems.map((item) => {

            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
    }

    return <>{finalNavItems()}</>;
};

export default MenuList;
