import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Response from '../../utils/response';

export const dashboardAsync = createAsyncThunk(
    'dashboard/dashboardAsync',
    async (payload) => {
        return await Response('GET_DASHBOARD_COUNTS', payload)
            .then((data) => {

                if (data.success)
                    return {
                        siteCount: data.siteCount,
                        siteSessionsCount: data.siteSessionsCount,
                        tappCount: data.tappCount
                    }

                return {
                    siteCount: 0,
                    siteSessionsCount: 0,
                    tappCount: 0
                }
            })
            .catch((error) => {
                return {
                    siteCount: 0,
                    siteSessionsCount: 0,
                    tappCount: 0
                }
            })
    }
);

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        dashboardData: {},
        isLoading: false,
        message: ""
    },
    extraReducers: {
        [dashboardAsync.pending]: (state, action) => {
            state.isLoading = true
        },
        [dashboardAsync.fulfilled]: (state, { payload }) => {
            state.isLoading = false,
            state.dashboardData = payload
        },
        [dashboardAsync.rejected]: (state, action) => {
            state.isLoading = false
        },
    }
});

export const dashboardState = (state) => state.dashboard;

export default dashboardSlice.reducer;