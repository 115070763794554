import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getErrorMessage } from 'utils';

import Response from '../../utils/response';

import { getEmptyData } from '../../utils/empty-types';

import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';
import { getVideoListAsync } from '../video/videoSlice';

export const getCourseAsync = createAsyncThunk(
    'getCourseAsync',
    async ({ dispatch, courseType }) => {

        dispatch(loaderStart());

        return await Response('GET_COURSE', { courseType })
            .then((data) => {
                console.log('getCourseAsync', data)
                dispatch(loaderEnd());

                if (data.success && data?.resultObj)

                    return data?.resultObj

                return []

            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const addCouseAsync = createAsyncThunk(
    'addCouseAsync',
    async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('ADD_COURSE', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    dispatch(getCourseAsync({ dispatch, courseType: data?.resultObj?.courseType }));

                    dispatch(setAlertMessage("Course saved successfully"));

                    return {}
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const removeCourseAsync = createAsyncThunk(
    'removeCourseAsync',
    async ({ courseId, checked, dispatch }) => {

        dispatch(loaderStart());

        return await Response('DELETE_COURSE', { courseId })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(getCourseAsync({ dispatch, courseType: checked ? 'paid' : 'free' }))

                    dispatch(setAlertMessage("Course removed successfully"));

                    return []
                }

                return []
            })
            .catch((error) => {
                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const editCourseAsync = createAsyncThunk(
    'editCourseAsync',
    async ({ courseId, dispatch }) => {

        dispatch(loaderStart());

        return await Response('GET_COURSE_BY_ID', { courseId })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    return data?.resultObj
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const getSpecificCourseAsync = createAsyncThunk(
    'getSpecificCourseAsync',
    async ({ courseId, dispatch }) => {

        dispatch(loaderStart());

        return await Response('GET_COURSE_BY_ID', { courseId })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    dispatch(getVideoListAsync(data?.resultObj?.videos));

                    return data?.resultObj
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const updateCourseAsync = createAsyncThunk(
    'updateCourseAsync',
    async ({ dispatch, postData, type }) => {

        dispatch(loaderStart());

        return await Response('UPDATE_COURSE', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    dispatch(getCourseAsync({ dispatch, courseType: data?.resultObj?.courseType }));

                    dispatch(setAlertMessage(`${(type.includes('Course') ? 'Course updated' : type) + ' successfully'}`));

                    return {}
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const courseSlice = createSlice({
    name: "course",
    initialState: {

        courseData: [],

        specificCourseData: {},

        editCourseData: {}
    },
    reducers: {

        clearCourseState: (state, { payload }) => {

            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {

        // Get
        [getCourseAsync.fulfilled]: (state, { payload }) => {

            state.courseData = payload
        },

        //Edit
        [editCourseAsync.fulfilled]: (state, { payload }) => {

            state.editCourseData = payload
        },

        //Get Specific Course details
        [getSpecificCourseAsync.fulfilled]: (state, { payload }) => {

            state.specificCourseData = payload
        },
    }
});

export const { clearCourseState } = courseSlice.actions;

export const courseState = (state) => state.course;

export default courseSlice.reducer;