export const getEmptyData = (type) => {

    switch (type) {

        case "array":
            return []
        case "string":
            return ""
        case "number":
            return 0
        default:
            return {}
    }

}