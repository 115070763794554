import { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import NotFoundRoutes from './NotFoundRoutes';
import config from 'config';

import { getRoleBasedRoutes } from '../utils';

// ==============================|| ROUTING RENDER ||============================== //


export default function ThemeRoutes() {

    let Routers = [
        AuthenticationRoutes,
        MainRoutes,
        NotFoundRoutes
    ];

    const routes = getRoleBasedRoutes(Routers);

    return useRoutes(Routers, config.basename);
}
