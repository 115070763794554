import { createSlice } from '@reduxjs/toolkit';

export const menuSlice = createSlice({
    name: "menu",
    initialState: {
        isOpen: [],
        opened: true
    },
    reducers: {
        menuToggle: (state, { payload }) => {
            state.isOpen = [payload.id]
        },
        setMenu: (state, { payload }) => {
            state.opened = payload.opened
        }
    }
});

export const { menuToggle, setMenu } = menuSlice.actions;

export const menuState = (state) => state.menu;

export default menuSlice.reducer;