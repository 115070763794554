import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { authRoles } from '../config/roles'

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// category master routing
const CategoryMaster = Loadable(lazy(() => import('views/pages/master/category/CategoryMaster')));
// category master routing
const SubCategoryMaster = Loadable(lazy(() => import('views/pages/master/subcategory/SubCategoryMaster')));
// course master routing
const CourseMaster = Loadable(lazy(() => import('views/pages/master/course/CourseMaster')));
// video master routing
const VideoMaster = Loadable(lazy(() => import('views/pages/master/video/VideoMaster')));
// explore  routing
const Explore = Loadable(lazy(() => import('views/pages/explore')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/dashboard',
    element: <MainLayout />,
    children: [
        {
            path: '/default',
            element: <DashboardDefault />,
            role : authRoles.admin,
        },
        {
            path: '/category-master',
            element: <CategoryMaster />,
            role : authRoles.admin,
        },
        {
            path: '/subcategory-master',
            element: <SubCategoryMaster />,
            role : authRoles.admin,
        },
        {
            path: '/course-master',
            element: <CourseMaster />,
            role : authRoles.user,
        },
        {
            path: '/video-master',
            element: <VideoMaster />,
            role : authRoles.user,
        },
        {
            path: '/explore',
            element: <Explore />,
            role : authRoles.user,
        }
    ]
};

export default MainRoutes;
