import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getErrorMessage } from 'utils';

import Response from '../../utils/response';

import { getEmptyData } from '../../utils/empty-types';

import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';
import { getCourseAsync, getSpecificCourseAsync } from '../course/courseSlice';

export const addVideoAsync = createAsyncThunk(
    'addVideoAsync',
    async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('ADD_VIDEO', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    dispatch(getSpecificCourseAsync({ courseId: data?.resultObj?.courseId, dispatch }))

                    dispatch(setAlertMessage("Video saved successfully"));

                    return {}
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const removeVideoAsync = createAsyncThunk(
    'removeVideoAsync',
    async ({ videoId, dispatch }) => {

        dispatch(loaderStart());

        return await Response('DELETE_VIDEO', { videoId })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(setAlertMessage("Video removed successfully"));

                    return []
                }

                return []
            })
            .catch((error) => {
                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const editVideoAsync = createAsyncThunk(
    'editVideoAsync',
    async ({ videoId, dispatch }) => {

        dispatch(loaderStart());

        return await Response('GET_VIDEO_BY_ID', { videoId })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    return data?.resultObj
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const updateVideoAsync = createAsyncThunk(
    'updateVideoAsync',
    async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('UPDATE_VIDEO', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    dispatch(getSpecificCourseAsync({ courseId : data?.resultObj?.courseId, dispatch }));

                    dispatch(setAlertMessage("Video updated successfully"));

                    return {}
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const updateThumbnailAsync = createAsyncThunk(
    'updateThumbnailAsync',
    async ({ dispatch, postThumbnailData }) => {

        dispatch(loaderStart());

        return await Response('CHANGE_VIDEO_THUMBNAIL', postThumbnailData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    dispatch(getSpecificCourseAsync({ courseId : data?.resultObj?.courseId, dispatch }));

                    dispatch(setAlertMessage("Video updated successfully"));

                    return {}
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const updateContentAsync = createAsyncThunk(
    'updateContentAsync',
    async ({ dispatch, postContentData }) => {

        dispatch(loaderStart());

        return await Response('CHANGE_VIDEO_CONTENT', postContentData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    dispatch(getSpecificCourseAsync({ courseId : data?.resultObj?.courseId, dispatch }));

                    dispatch(setAlertMessage("Video updated successfully"));

                    return {}
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const rearrangeContentAsync = createAsyncThunk(
    'rearrangeContentAsync',
    async ({ dispatch, postData, courseType }) => {

        dispatch(loaderStart());

        return await Response('REARRANGE_PLAYLISTS', postData, courseType)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    dispatch(getCourseAsync({ dispatch, courseType }));

                    dispatch(setAlertMessage("Video Playlist Rearranged successfully"));

                    return {}
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const videoSlice = createSlice({
    name: "video",
    initialState: {
        videoData: [],

        editVideoData: {}
    },
    reducers: {

        getVideoListAsync: (state, { payload }) => {

            state.videoData = payload;
        },

        clearVideoState: (state, { payload }) => {

            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {

        //Edit
        [editVideoAsync.fulfilled]: (state, { payload }) => {

            state.editVideoData = payload
        },
    }
});

export const { getVideoListAsync, clearVideoState } = videoSlice.actions;

export const videoState = (state) => state.video;

export default videoSlice.reducer;