import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getErrorMessage } from 'utils';
import Response from '../../utils/response';
import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const loginAsync = createAsyncThunk(
    'loginAsync',
    async ({username, password , dispatch}) => {

        dispatch(loaderStart());

        return await Response('LOGIN_USER', { username, password })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    dispatch(setAlertMessage("Logged In successfully"));

                    return { user: data.resultObj, isLoggedIn: true }
                }

                return { user: {}, isLoggedIn: false }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return { user: {}, isLoggedIn: false }
            })
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState: {

        userData: {},

        isLoggedIn: false
    },
    reducers : {

        logout : (state) => {

            state.userData = {},

            state.isLoggedIn = false,

            localStorage.clear();
        }
    },
    extraReducers: {

        [loginAsync.fulfilled]: (state, { payload }) => {

            state.userData = payload.user,

            state.isLoggedIn = payload.isLoggedIn

            if (payload.user?._id != undefined) {
                
                const { _id, name, email, user_type, username, isExternalCreator, token } = payload.user;

                localStorage.setItem('accessToken', token.split(" ")[1]);

                localStorage.setItem('profile', JSON.stringify({ _id, name, email, user_type, username, isExternalCreator }));
            }
        }
    }
});

export const { logout } = authSlice.actions;

export const loginState = (state) => state.auth;

export default authSlice.reducer;