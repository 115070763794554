import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getErrorMessage } from 'utils';
import Response from '../../utils/response';
import { getEmptyData } from '../../utils/empty-types';
import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getCategoryAsync = createAsyncThunk(
    'getCategoryAsync',
    async (dispatch) => {

        dispatch(loaderStart());

        return await Response('GET_CATEGORY')
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj)

                    return data?.resultObj

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const addCategoryAsync = createAsyncThunk(
    'addCategoryAsync',
    async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('ADD_CATEGORY', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(getCategoryAsync(dispatch));

                    dispatch(setAlertMessage("Category saved successfully"));

                    return []
                }

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const removeCategoryAsync = createAsyncThunk(
    'removeCategoryAsync',
    async ({ categoryId, dispatch }) => {

        dispatch(loaderStart());

        return await Response('DELETE_CATEGORY', { categoryId })
            .then((data) => {
                dispatch(loaderEnd());

                if (data.success) {
                    dispatch(getCategoryAsync(dispatch));
                    dispatch(setAlertMessage("Category removed successfully"));
                    return []
                }

                return []
            })
            .catch((error) => {
                dispatch(loaderEnd());
                dispatch(setAlertMessage(getErrorMessage(error.message)));
                return []
            })
    }
);

export const editCategoryAsync = createAsyncThunk(
    'editCategoryAsync',
    async ({ categoryId, dispatch }) => {
        dispatch(loaderStart());

        return await Response('GET_CATEGORY_BY_ID', { categoryId })
            .then((data) => {
                dispatch(loaderEnd());

                if (data.success && data?.resultObj)
                    return data?.resultObj

                return {}
            })
            .catch((error) => {
                dispatch(loaderEnd());
                dispatch(setAlertMessage(getErrorMessage(error.message)));
                return {}
            })
    }
);

export const updateCategoryAsync = createAsyncThunk(
    'updateCategoryAsync',
    async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('UPDATE_CATEGORY', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    dispatch(getCategoryAsync(dispatch));

                    dispatch(setAlertMessage("Category updated successfully"));

                    return data?.resultObj
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const categorySlice = createSlice({
    name: "category",
    initialState: {
        categoryData: [],
        editCategoryData: {}
    },
    reducers: {
        clearCategoryState: (state, { payload }) => {
            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {

        // Get
        [getCategoryAsync.fulfilled]: (state, { payload }) => {
            state.categoryData = payload
        },

        //Edit
        [editCategoryAsync.fulfilled]: (state, { payload }) => {
            state.editCategoryData = payload
        },
    }
});

export const { clearCategoryState } = categorySlice.actions;

export const categoryState = (state) => state.category;

export default categorySlice.reducer;