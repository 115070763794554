import axios from 'axios';
import config from '../config';
import apiEndpoints from 'config/api-endpoints';

const { base_url } = config;

export default Response = async (key, data) => {

    let params = apiEndpoints[key];
    let url = base_url + params
    let method = 'GET'

    const paramsArray = params.split(' ')

    if (paramsArray.length >= 2) {
        method = paramsArray[0]
        url = base_url + paramsArray[1]
    }

    let queryParams = '';

    if (params.includes('?')) {
        Object.entries(data).map(item => {
            if (queryParams == '') {
                queryParams = `${item[0]}=${item[1]}`;
            }
            else {
                queryParams += `&${item[0]}=${item[1]}`;
            }
        });

        url += queryParams;
    }

    return await Request({
        url,
        data,
        method,
    })
}

const Request = async (options) => {

    axios.interceptors.request.use((req) => {
        if (localStorage.getItem('accessToken')) {
            req.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
        }
    
        return req;
    });

    return await axios(options)
        .then(response => {

            const { statusText, status, data } = response

            let result = {}
            if (typeof data === 'object') {
                result = data
                if (Array.isArray(data)) {
                    result.list = data
                }
            } else {
                result.data = data
            }

            return Promise.resolve({
                success: true,
                message: statusText,
                statusCode: status,
                ...result,
            })
        })
        .catch(error => {

            const { response, message } = error

            let msg
            let statusCode

            if (response && response instanceof Object) {
                const { data, statusText } = response
                statusCode = response.status
                msg = data.message || statusText
            } else {
                statusCode = 600
                msg = error.message || 'Network Error'
            }

            return Promise.reject({
                success: false,
                statusCode,
                message: msg,
            })
        })
}
