import React, { useEffect } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { loaderState, setAlertMessage } from '../../features/loader/loaderSlice';
import { logout } from '../../features/auth/authSlice';

export default function CustomAlert() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { message } = useSelector(loaderState);
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");

    useEffect(() => {

        message != '' ? setOpen(true) : setOpen(false);

        if (message.includes('successfully')) {

            setSeverity("success")
        }
        else {

            setSeverity("error")

            if (message.includes('expired')) {
                dispatch(logout());

                navigate('/');

                dispatch(setAlertMessage("Session expired, Please login again."));
            }
        }

    }, [message]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        dispatch(setAlertMessage(""));
    };

    return (
        <>
            {message != '' && <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>}
        </>
    );
}
