import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getErrorMessage } from 'utils';

import Response from '../../utils/response';

import { getEmptyData } from '../../utils/empty-types';

import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getSubCategoryAsync = createAsyncThunk(
    'getSubCategoryAsync',
    async (dispatch) => {

        dispatch(loaderStart());

        return await Response('GET_SUBCATEGORY')
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj)

                    return data?.resultObj

                return []

            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const addSubCategoryAsync = createAsyncThunk(
    'addSubCategoryAsync',
    async ({ dispatch, postData, categoryIdArray }) => {

        dispatch(loaderStart());

        return await Response('ADD_SUBCATEGORY', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    dispatch(linkSubCategoryAsync({ dispatch, categories: categoryIdArray, sub_category_id: data?.resultObj?._id, type: 'SAVE' }));

                    return {}
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const linkSubCategoryAsync = createAsyncThunk(
    'linkSubCategoryAsync',
    async ({ dispatch, categories, sub_category_id, type }) => {

        dispatch(loaderStart());

        return await Response('LINK_SUBCATEGORY', { categories, sub_category_id })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(getSubCategoryAsync(dispatch));

                    dispatch(setAlertMessage(`SubCategory ${type === 'SAVE' ? 'Saved' : 'Updated'} successfully`));

                    return []
                }

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const removeSubCategoryAsync = createAsyncThunk(
    'removeSubCategoryAsync',
    async ({ subCategoryId, dispatch }) => {

        dispatch(loaderStart());

        return await Response('DELETE_SUBCATEGORY', { subCategoryId })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(getSubCategoryAsync(dispatch));

                    dispatch(setAlertMessage("SubCategory removed successfully"));

                    return []
                }

                return []
            })
            .catch((error) => {
                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const editSubCategoryAsync = createAsyncThunk(
    'editSubCategoryAsync',
    async ({ subCategoryId, dispatch }) => {

        dispatch(loaderStart());

        return await Response('GET_SUBCATEGORY_BY_ID', { subCategoryId })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    return data?.resultObj
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const updateSubCategoryAsync = createAsyncThunk(
    'updateSubCategoryAsync',
    async ({ dispatch, postData, categoryIdArray }) => {

        dispatch(loaderStart());

        return await Response('UPDATE_SUBCATEGORY', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    dispatch(linkSubCategoryAsync({ dispatch, categories: categoryIdArray, sub_category_id: data?.resultObj?._id, type: 'UPDATE' }));

                    return {}
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const subcategorySlice = createSlice({
    name: "subcategory",
    initialState: {

        subCategoryData: [],

        editSubCategoryData: {}
    },
    reducers: {

        clearSubCategoryState: (state, { payload }) => {

            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {

        // Get
        [getSubCategoryAsync.fulfilled]: (state, { payload }) => {

            state.subCategoryData = payload
        },

        //Edit
        [editSubCategoryAsync.fulfilled]: (state, { payload }) => {

            state.editSubCategoryData = payload
        },
    }
});

export const { clearSubCategoryState } = subcategorySlice.actions;

export const subCategoryState = (state) => state.subcategory;

export default subcategorySlice.reducer;