import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getErrorMessage } from 'utils';
import Response from '../../utils/response';
import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getExploreAsync = createAsyncThunk(
    'getExploreAsync',
    async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('GET_EXPLORE', postData)
            .then((data) => {
                console.log('getExploreAsync res', data)
                dispatch(loaderEnd());

                if (data.success && data?.resultObj)

                    return data?.resultObj

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const addExploreAsync = createAsyncThunk(
    'addExploreAsync',
    async ({ dispatch, postData }) => {
        console.log('addExploreAsync', postData);
        dispatch(loaderStart());

        return await Response('ADD_COURSE_TO_EXPLORE', postData)
            .then((data) => {
                console.log('addExploreAsync response', data);
                dispatch(loaderEnd());

                if (data.success) {

                    //dispatch(getCategoryAsync(dispatch));

                    dispatch(setAlertMessage("Course added to explore successfully"));

                    return []
                }

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const updateOrRemoveCategoryAsync = createAsyncThunk(
    'updateOrRemoveCategoryAsync',
    async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('UPDATE_OR_REMOVE_COURSE_FROM_EXPLORE', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    //dispatch(getCategoryAsync(dispatch));

                    dispatch(setAlertMessage("Category updated successfully"));

                    return data?.resultObj
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const exploreSlice = createSlice({
    name: "explore",
    initialState: {
        exploreData: []
    },
    extraReducers: {

        // Get
        [getExploreAsync.fulfilled]: (state, { payload }) => {
            state.exploreData = payload
        },

    }
});

export const exploreState = (state) => state.explore;

export default exploreSlice.reducer;