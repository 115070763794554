import React, { useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { useSelector } from 'react-redux';

import { loaderState } from '../../features/loader/loaderSlice';

import './styles.css';

export default function CustomLoader() {

  const { isLoading } = useSelector(loaderState);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    isLoading ? setOpen(true) : setOpen(false);
  }, [isLoading]);

  return (
    <div>
      <Backdrop
        sx={{ color: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <div className="loadingio-spinner-ripple-ndtmo8apcje">
          <div className="ldio-7yoh2apqfgo">
            <div></div><div></div>
          </div>
        </div>

      </Backdrop>
    </div>
  );
}
